import React, { useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import "../css/Navbar.css";
import logo from "../media/logoname.png"; // Import the logo image
import BookNow from "./BookNow"; // Import the BookNow component

const Navbar = () => {
  const [menuOpen, setMenuOpen] = useState(false); // State to handle mobile menu
  const navigate = useNavigate();
  const location = useLocation(); // Get the current location

  const handleScrollTo = (sectionId) => {
    const section = document.getElementById(sectionId);
    if (section) {
      section.scrollIntoView({ behavior: "smooth" });
    }
  };

  const handleNavigation = (sectionId) => {
    if (location.pathname === "/") {
      // If already on the homepage, scroll directly
      handleScrollTo(sectionId);
    } else {
      // Navigate to the homepage with hash
      navigate(`/#${sectionId}`);
    }
    setMenuOpen(false); // Close the menu after navigation
  };

  const toggleMenu = () => {
    setMenuOpen(!menuOpen); // Toggle the state of the menu
  };

  return (
    <nav className="navbar">
      <div className="logo" onClick={() => handleNavigation("home")}>
        <img src={logo} alt="Kiddie Kingdom Logo" className="logo-image" />
      </div>
      <ul className={`menu ${menuOpen ? "active" : ""}`}>
        <li onClick={() => handleNavigation("home")}>Home</li>
        <li onClick={() => handleNavigation("packages")}>Packages</li>
        <li onClick={() => handleNavigation("gallery")}>Gallery</li>
        <li onClick={() => handleNavigation("rules-waiver")}>Waiver</li>
        <li onClick={() => handleNavigation("waiver")}>FAQ</li>
        <li onClick={() => handleNavigation("contact")}>Contact</li>
        <li className="phone-container">
          <i className="fas fa-phone icon-phone"></i>
          <a href="tel:+18188184270" className="phone-link">
            818-818-4270
          </a>
        </li>
      </ul>
      <div className="book-now-container">
        <BookNow />
      </div>
      {/* Hamburger Icon for Mobile */}
      <div className="hamburger" onClick={toggleMenu}>
        <div className="bar"></div>
        <div className="bar"></div>
        <div className="bar"></div>
      </div>
    </nav>
  );
};

export default Navbar;
