import React from "react";
import "../css/PackagesDetail.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheckCircle } from "@fortawesome/free-solid-svg-icons";
import Navbar from "./Navbar";
import Footer from "./Footer";
import BookNow from "./BookNow";

const packagesData = [
  {
    id: 1,
    title: "Prince/Princess Celebration",
    price: "$1,650",
    serviceFee: true,

    details: [
      { label: "Party Time", value: "2 Hours" },
      { label: "Kids", value: "Up to 20 (Each Additional Kid $20)" },
      { label: "Adult", value: "Up to 25 (Each Additional Adult $15)" },
      { label: "Helpers", value: "2-3" },
      { label: "Large 1 Topping Pizza", value: "4" },
      { label: "Organic Juice Boxes", value: "20" },
      { label: "Water Bottles", value: "For All Guests" },
      { label: "Non-Slip Socks", value: "For All kids" },
    ],
    additionalInfo: [
      "Coffee/Tea served with creamer & sugar",
      "Plates, napkins, cups, flatware for adults",
      "Cake celebration (cutting, serving)",
      "Cake stands provided (acrylic)",
      "Complimentary background music for extra fun and cheer!",
    ],
  },

  {
    id: 2,
    // title: "Fairy Tale    Celebration",
    title: (
      <>
        Fairy Tale <br /> Celebration
      </>
    ),
    price: "$2,150",
    serviceFee: true,

    details: [
      { label: "Party Time", value: "2.5 Hours" },
      { label: "Kids", value: "Up to 25 (Each Additional Kid $20)" },
      { label: "Adult", value: " Up to 30 (Each Additional Adult $15)" },
      { label: "Helpers", value: "2-3" },
      { label: "Large 1 Topping Pizza", value: "5" },
      { label: "Organic Juice Boxes", value: "25" },
      { label: "Water Bottles", value: "For All Guests" },
      { label: "Non-Slip Socks", value: "For All Kids" },
    ],
    additionalInfo: [
      "Coffee/Tea served with creamer & sugar",
      "Themed plates napkins, and centerpieces for the kid's table",
      "Plates, napkins, cups, flatware for adults",
      "Cake celebration (cutting, serving)",
      "Cake plates & forks provided",
      "Cake stands are provided, with clear, white options and a variety of colors and designs to choose from",
      "Complimentary background music for extra fun and cheer!",
    ],
  },
  {
    id: 3,
    title: "Luminous Castle Celebration",
    price: "$2,750",
    serviceFee: true,

    details: [
      { label: "Party Time", value: "3 Hours" },
      { label: "Kids", value: "Up to 30 (Each Additional Kid $20)" },
      { label: "Adult", value: "Up to 35 (Each Additional Adult $15)" },
      { label: "Helpers", value: "2-3" },
      { label: "Large 1 Topping Pizza", value: "7" },
      { label: "Organic Juice Boxes", value: "30" },
      { label: "Sodas", value: "For Adults" },
      { label: "Water Bottles", value: "For All Guests" },
      { label: "Non-Slip Socks", value: "For All Kids" },
    ],
    additionalInfo: [
      "Coffee/Tea served with creamer & sugar",
      "Themed plates napkins, and centerpieces for the kid's table",
      "Plates, napkins, cups, flatware for adults",
      "Cake celebration (cutting, serving)",
      "Cake plates & forks provided",
      "Cake stands are provided, with clear, white options and a variety of colors and designs to choose from",
      "Goodie bags for all kids",
      "Goodie bag display stand provided",
      "Complimentary background music for extra fun and cheer!",
    ],
  },
  {
    id: 4,
    title: "Majestic Royal Celebration",
    price: "$4,500",
    serviceFee: true,

    details: [
      { label: "Party Time", value: "4 Hours" },
      { label: "Kids", value: "Up to 40 (Each Additional Kid $20)" },
      { label: "Adult", value: "Up to 45 (Each Additional Adult $15)" },
      { label: "Helpers", value: "2-3" },
      { label: "Large 1 Topping Pizza", value: "8" },
      { label: "Organic Juice Boxes", value: "Unlimited" },
      { label: "Sodas", value: "For Adults" },
      { label: "Water Bottles", value: "For All Guests" },
      { label: "Non-Slip Socks", value: "For All Kids" },
    ],
    additionalInfo: [
      "Coffee/Tea served with creamer & sugar",
      "Themed plates napkins, and centerpieces for the kid's table",
      "Plates, napkins, cups, flatware for adults",
      "Cake celebration (cutting, serving)",
      "Cake plates & forks provided",
      "Cake stands are provided, with clear, white options and a variety of colors and designs to choose from",
      "Goodie bags for all kids",
      "Goodie bag display stand provided",
      "Character for your themed party (1 hour) OR Sparkly painting and glitter tattoos",
      "1 free return open play pass for the birthday child and their sibling(s)",
      "Complimentary background music for extra fun and cheer!",
    ],
  },
];
const packagesData2 = [
  {
    id: 1,
    title: "Add on",
    price: "From $25",
    serviceFee: true,

    details: [
      {
        label: "+30mins of playtime",
        value: "$300 (only for packages)",
      },
      { label: "+1hr of playtime", value: "$400 (only for packages)" },
      { label: "Cotton Candy Station", value: " $200" },
      { label: "Sparkly Painting & Glitter Tattoos", value: " $150/1hr" },
      { label: "Friendship Bracelet-Making", value: " $200/1hr" },
      { label: "Balloon Decorations", value: "Starting at $450" },
      { label: "hemed Party Character Appearance", value: "Starting at $250" },
      { label: "Character Cutouts", value: "Starting at $50" },
      { label: "Arch Backdrops", value: "Starting at $150" },
      { label: "Professional Photography", value: "Starting at $150/1hr" },
      {
        label: "Food Catering",
        value: "Price Varies",
      },
      {
        label: "Royal Sipper Cups",
        value: "Price Varies",
      },
      {
        label: "Themed Marshmallow Treats.",
        value: "$90(20cnt)",
      },
      { label: "Party Goodie Bags", value: " $10 each" },
      { label: "Goodie Bag Display Stand (one)", value: " $150" },
      { label: "Large One-Topping Pizza (one)", value: " $25 each" },
    ],
    additionalInfo: [],
  },
  {
    id: 2,
    title: "Rent Out Location",
    price: "$1000",
    serviceFee: true,
    details: [
      { label: "Duration", value: "1 Hour" },
      {
        label:
          "For photoshoots, commercials, and private play only. Birthdays are not permitted!",
        value: "",
      },
    ],
    additionalInfo: [],
  },
];
const packagesData3 = [
  {
    id: 1,
    title: "Non Private Party",
    price: "$300",
    serviceFee: true,

    details: [
      // { label: "Monday - Thursday Only", value: "" },
      { label: "Party Time", value: "2 Hours" },
      { label: "5 kids and 10 adults", value: "" },

      { label: "2 boxes of pizza (1 topping)", value: "" },
      { label: "Non-Slip Socks", value: "For All Kids" },
      { label: "Coffee/Tea served with creamer & sugar", value: "" },
      { label: "Water Bottles", value: "For All Guests" },
      { label: "Organic Juice Boxes", value: "For all kids" },
      {
        label: "Additional food and cake are allowed.",
        value: "",
      },
      // {
      //   label: "Each Additional Kid ",
      //   value: "$20",
      // },
      // {
      //   label: "Each Additional Adult ",
      //   value: "$15",
      // },
      {
        label: "+ 30 mins of playtime",
        value: "$200",
      },
      { label: "+ 1 hr of playtime", value: "$300" },
    ],
    additionalInfo: [],
  },
  {
    id: 2,
    title: "Non Private Party",
    price: "$700",
    serviceFee: true,
    details: [
      // { label: "Monday - Thursday Only", value: "" },

      { label: "Party Time", value: "2 Hours" },
      { label: "15 kids and 20 adults", value: "" },

      { label: "3 boxes of pizza (1 topping)", value: "" },
      { label: "Non-Slip Socks", value: "For All Kids" },
      { label: "Coffee/Tea served with creamer & sugar", value: "" },
      { label: "Water Bottles", value: "For All Guests" },
      { label: "Organic Juice Boxes", value: "For all kids" },
      {
        label: "Additional food and cake are allowed.",
        value: "",
      },
      // {
      //   label: "Each Additional Kid ",
      //   value: "$20",
      // },
      // {
      //   label: "Each Additional Adult ",
      //   value: "$15",
      // },
      {
        label: "+ 30 mins of playtime",
        value: "$200",
      },
      { label: "+ 1 hr of playtime", value: "$300" },
    ],
    additionalInfo: [],
  },
];
const packagesData4 = [
  {
    id: 1,
    title: "",
    price: "",
    serviceFee: false,

    details: [
      {
        label:
          "A 40% non-refundable deposit is required at the time of booking.",
        value: "",
      },
      {
        label: "Vendor Fee $150/per Vendor",
        value: "",
      },
      {
        label:
          "You may reschedule your event one time only, subject to availability.",
        value: "",
      },
      {
        label:
          "To cancel or reschedule your event date, a 30-day notice is required. The new date (if rescheduled) must also be within the same calendar year.",
        value: "",
      },
      {
        label: "The remaining balance must be paid on the day of the event.",
        value: "",
      },
      {
        label:
          "A 15% service fee will be added to all events and is due with the remaining balance. This is not a tip. The service charge helps cover the time spent preparing, planning, setting up, and cleaning up before, during, and after your event.",
        value: "",
      },
      {
        label: "A 10.25% tax will be applied to the total balance.",
        value: "",
      },
      {
        label:
          "A 3% credit card fee will be charged if payment is made by card.",
        value: "",
      },
      {
        label:
          "There is a 15-minute grace period after your party ends. Exceeding this time will incur a $300 fee.",
        value: "",
      },
      {
        label:
          "Once the party ends, staff will begin cleanup to prepare for open play or the next scheduled party.",
        value: "",
      },
      {
        label:
          "All decorations must be removed at the end of the party, with staff available to assist.",
        value: "",
      },
      {
        label: "Backdrops cannot be hung on the walls.",
        value: "",
      },
      {
        label:
          "All guests entering the play area must remove their shoes and wear non-slip socks. If play mats are damaged, a $450 fee will be charged.",
        value: "",
      },
      {
        label:
          "No food or beverages are allowed in the play area. If the play area is dirtied or damaged due to food or beverages, a $300 fee will be applied.",
        value: "",
      },
      {
        label:
          "Damaging items in the party area, play area, restrooms, or other spaces due to mishandling will result in a $500 fee.",
        value: "",
      },
    ],
    additionalInfo: [],
  },
];

const PackagesDetail = () => {
  return (
    <>
      <Navbar />
      <div className="packages-detail-section">
        <h1 className="section-title">Party Packages</h1>
        <div className="packages-grid">
          {packagesData.map((pkg) => (
            <div key={pkg.id} className="package-detail-card">
              <h2 className="package-title">{pkg.title}</h2>
              <div className="price-box">
                <h3 className="package-price">{pkg.price}</h3>
                {pkg.serviceFee && (
                  <p className="package-tax">+15% SERVICE FEE AND 10.25% TAX</p>
                )}{" "}
              </div>
              <ul className="package-details">
                {pkg.details.map((detail, index) => (
                  <li key={index}>
                    {detail.label} <span>{detail.value}</span>
                  </li>
                ))}
              </ul>
              {/* Display additional info if it exists */}
              {pkg.additionalInfo && pkg.additionalInfo.length > 0 && (
                <div className="additional-info">
                  <ul>
                    {pkg.additionalInfo.map((info, index) => (
                      <li key={index}>
                        <FontAwesomeIcon
                          icon={faCheckCircle}
                          className="check-icon"
                        />{" "}
                        {info}
                      </li>
                    ))}
                  </ul>
                </div>
              )}
              <div className="book-now-container package-book-now">
                <BookNow />
              </div>
            </div>
          ))}
        </div>

        <h1 class="section-title">Non Private Party Packages</h1>
        <h2 class="section-title2">(Monday - Thursday Only)</h2>
        {/* Second Packages Section Privaty duirng open play */}
        <div className="packages-grid-3">
          {packagesData3.map((pkg) => (
            <div key={pkg.id} className="package-detail-card">
              <h2 className="package-title">{pkg.title}</h2>
              <div className="price-box">
                <h3 className="package-price">{pkg.price}</h3>
                {pkg.serviceFee && (
                  <p className="package-tax">+15% SERVICE FEE AND 10.25% TAX</p>
                )}
              </div>
              <ul className="package-details">
                {pkg.details.map((detail, index) => (
                  <li key={index}>
                    {detail.label} <span>{detail.value}</span>
                  </li>
                ))}
              </ul>
              {pkg.additionalInfo && pkg.additionalInfo.length > 0 && (
                <div className="additional-info">
                  <ul>
                    {pkg.additionalInfo.map((info, index) => (
                      <li key={index}>
                        <FontAwesomeIcon
                          icon={faCheckCircle}
                          className="check-icon"
                        />{" "}
                        {info}
                      </li>
                    ))}
                  </ul>
                </div>
              )}
            </div>
          ))}
        </div>

        {/* Third Packages Section Add on */}
        <div className="packages-grid-2">
          {packagesData2.map((pkg) => (
            <div key={pkg.id} className="package-detail-card">
              <h2 className="package-title">{pkg.title}</h2>
              <div className="price-box">
                <h3 className="package-price">{pkg.price}</h3>
                {pkg.serviceFee && (
                  <p className="package-tax">+15% SERVICE FEE AND 10.25% TAX</p>
                )}
              </div>
              <ul className="package-details">
                {pkg.details.map((detail, index) => (
                  <li key={index}>
                    {detail.label} <span>{detail.value}</span>
                  </li>
                ))}
              </ul>
              {pkg.additionalInfo && pkg.additionalInfo.length > 0 && (
                <div className="additional-info">
                  <ul>
                    {pkg.additionalInfo.map((info, index) => (
                      <li key={index}>
                        <FontAwesomeIcon
                          icon={faCheckCircle}
                          className="check-icon"
                        />{" "}
                        {info}
                      </li>
                    ))}
                  </ul>
                </div>
              )}
            </div>
          ))}
        </div>
        {/* Add Booking Policies Section */}
        <h1 className="section-title">Booking Policies</h1>
        <div className="packages-grid-4">
          {packagesData4.map((pkg) => (
            <div key={pkg.id} className="package-detail-card">
              <h2 className="package-title">{pkg.title}</h2>
              <ul className="package-details">
                {pkg.details.map((detail, index) => (
                  <li key={index}>
                    {detail.label} <span>{detail.value}</span>
                  </li>
                ))}
              </ul>
            </div>
          ))}
        </div>
      </div>

      <Footer />
    </>
  );
};
export default PackagesDetail;
