import React from "react";
import "../css/RulesandWaiver.css";
// import { useNavigate } from "react-router-dom"; // For navigation
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCrown } from "@fortawesome/free-solid-svg-icons";

const RulesAndWaiver = () => {
  // const navigate = useNavigate();

  // const handleSignWaiver = () => {
  //   //history.push("/sign-waiver"); // Navigate to the sign waiver page
  //   //https://forms.fillout.com/t/qTow1B64kAus
  // };
  const handleSignWaiver = () => {
    // Redirect to the Fillout form link
    window.open("https://forms.fillout.com/t/qTow1B64kAus", "_blank");
  };
  return (
    <div className="rules-section">
      {/* Background text */}
      <div className="rules-background-text">GUIDELINES</div>
      <div className="waiver-container">
        <div className="waiver-card">
          <h2 className="waiver-title">Playground Rules</h2>
          <ul className="waiver-rules">
            <li>
              <FontAwesomeIcon icon={faCrown} /> All children must wear grip
              socks for safety, while adults are required to wear standard
              socks.
            </li>
            <li>
              <FontAwesomeIcon icon={faCrown} /> Children must always be
              supervised by a parent or guardian.
            </li>
            <li>
              <FontAwesomeIcon icon={faCrown} /> Shoes are not allowed in the
              playground.
            </li>
            <li>
              <FontAwesomeIcon icon={faCrown} /> Outside food and drinks are
              permitted.
            </li>
            <li>
              <FontAwesomeIcon icon={faCrown} /> Guests are responsible for
              their personal belongings. Please make sure to empty pockets
              before entering the ball pit.
            </li>
            <li>
              <FontAwesomeIcon icon={faCrown} /> Adults are not allowed on the
              trampolines.
            </li>
            <li>
              <FontAwesomeIcon icon={faCrown} />
              Aggressive behavior or the use of inappropriate language is
              prohibited.
            </li>
            <li>
              <FontAwesomeIcon icon={faCrown} /> Food and drinks are not allowed
              in the play areas.
            </li>
            <li>
              <FontAwesomeIcon icon={faCrown} />
              Follow equipment use guidelines to avoid injuries. We are not
              liable for injuries caused by misuse.
            </li>
            <li>
              <FontAwesomeIcon icon={faCrown} /> Smoking, vaping, or alcohol are
              not permitted.
            </li>
            <li>
              <FontAwesomeIcon icon={faCrown} />A $450 fee will apply for any
              damage to our play mats resulting from not following the rules.
            </li>
            <li>
              <FontAwesomeIcon icon={faCrown} />A $500 fee will be charged for
              any damage to items in the party area, play area, restrooms, or
              elsewhere due to improper handling.
            </li>
          </ul>
          <button className="sign-waiver-btn" onClick={handleSignWaiver}>
            Sign Waiver
          </button>
        </div>
      </div>
    </div>
  );
};

export default RulesAndWaiver;
