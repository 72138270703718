import React from "react";
import "../css/Packages.css";
import { useNavigate } from "react-router-dom";

const packagesData = [
  {
    id: 1,
    title: "Daily Pass & Membership",
    details: [
      {
        time: "Monday - Sunday    10:00 AM - 8:00 PM",
        priceDetails: [
          "1st Child — $30/3 Hours",
          "2nd Child — $25/3 Hours",
          "3rd Child — $20/3 Hours",
        ],
        membershipInfo: [
          "Join our playground family and unlock endless fun whenever you want! With our membership, you can skip the booking stress and drop by for playtime whenever the space is free from events. Fun and excitement await you at every visit!",
          "Monthly Membership:",
          "* Three hours playtime whenever the space is free from events",
          "1st Child - $250 for 10 sessions",
          "2nd Child - $225 for 10 sessions",
          "3rd Child - $205 for 10 sessions",
        ],
      },
    ],
  },
  {
    id: 2,
    title: "Prince/Princess Celebration",
    price: "$1650",
    detailsPage: "/p1",
  },
  {
    id: 3,
    title: "Fairy Tale Celebration",
    price: "$2150",
    detailsPage: "/p2",
  },
  {
    id: 4,
    title: "Castle Celebration",
    price: "$2750",
    detailsPage: "/p3",
  },
  {
    id: 5,
    title: "Majestic Royal Celebration",
    price: "$4500",
    detailsPage: "/p4",
  },
  {
    id: 6,
    title: "Non Private Party",
    price: "From $300",
    detailsPage: "/p5",
  },
];

const Packages = () => {
  const navigate = useNavigate();

  const handlePackageClick = (detailsPage) => {
    navigate("/packagesdetail");
    window.scrollTo(0, 0);
  };

  return (
    <div className="packages-section">
      {/* Background text */}
      <div className="packages-background-text">PACKAGES</div>

      {/* Main card container */}
      <div className="package-card-container">
        {/* First row: Party Packages */}
        <div className="package-row">
          {packagesData.slice(1).map((pkg) => (
            <div className="package-box" key={pkg.id}>
              <h3>{pkg.title}</h3>
              <h4>{pkg.price}</h4>
              <p>{pkg.description || "Make your event magical!"}</p>
              <button
                className="details-button"
                onClick={() => handlePackageClick(pkg.detailsPage)}
              >
                Details
              </button>
            </div>
          ))}
        </div>

        {/* Spacer */}
        <div className="row-spacer"></div>

        {/* Second row: Daily Pass & Membership */}
        <div className="package-row">
          <div className="package-box">
            <h3>Daily Pass</h3>
            <p className="open-play-hours">
              <strong>Open Play Hours:</strong>
            </p>
            <p className="open-play-time">{packagesData[0].details[0].time}</p>
            <ul className="pricing-list">
              {packagesData[0].details[0].priceDetails.map((detail, index) => (
                <li key={index}>{detail}</li>
              ))}
            </ul>
            <p className="note">* 2 free adults per family</p>
          </div>

          <div className="package-box monthly-membership">
            <h3>Monthly Membership</h3>
            <h4>Membership Pricing:</h4>
            <ul className="pricing-list">
              {packagesData[0].details[0].membershipInfo
                .slice(2)
                .map((info, index) => (
                  <li key={index}>{info}</li>
                ))}
            </ul>
            <p>{packagesData[0].details[0].membershipInfo[0]}</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Packages;
