import React, { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheckCircle } from "@fortawesome/free-solid-svg-icons"; // Import the checkmark
import "../css/Faq.css"; // Import the FAQ-specific CSS

const FAQData = [
  {
    question: "Is Kiddie Kingdom a safe environment for my child?",
    answer:
      "Absolutely! At Kiddie Kingdom, safety is our number one priority. Our play structures are designed with care to ensure a secure environment, and we encourage parents or guardians to actively supervise their children during playtime to guarantee a safe and fun experience for everyone.",
  },
  {
    question: "What are your open play hours?",
    answer:
      "Our open play hours vary, so be sure to check our website or social media for the latest updates. We occasionally host private events that may affect our regular schedule.",
  },
  {
    question: "Do I need to make a reservation for open play?",
    answer:
      "While walk-ins are welcome, we highly recommend making a reservation, especially during weekends and holidays, to ensure we have space for your child.",
  },
  {
    question: "Can I drop off my kids?",
    answer:
      "While Kiddie Kingdom is designed for families to enjoy together, children must be accompanied by an adult (18 years or older). We are not a daycare facility, but we offer a fantastic shared experience for both children and their guardians.",
  },
  {
    question: "What age range is Kiddie Kingdom suitable for?",
    answer:
      "Kiddie Kingdom is designed for children aged 1 to 10 years old, with various play structures that cater to different age groups.",
  },
  {
    question: "Are there any rules for using the play structures?",
    answer:
      "We ask that children play safely and follow the guidance of our staff. Climbing on the outside of the play structures is not allowed, and all guests must wear socks at all times.",
  },
  {
    question: "How clean is the Kiddie Kingdom playground?",
    answer:
      "Cleanliness is very important to us. Our facilities are thoroughly sanitized throughout the day, and we adhere to strict cleaning protocols to ensure a healthy environment for all our guests. We also encourage everyone to wear socks to maintain hygiene in our play areas.",
  },
  {
    question: "Can I bring in food or snacks?",
    answer:
      "Yes, you're welcome to bring your own food and snacks for events such as birthdays. If you're hosting a party at Kiddie Kingdom, let us know beforehand so we can help make arrangements. You can email us at kiddiekingdomplayground@gmail.com to discuss the details.",
  },
  {
    question:
      "Can I bring a cake for my child’s birthday party even if I don’t book a package?",
    answer:
      "Yes, you can bring a cake to celebrate your child’s special day! Even if you haven't booked a specific package, we’re happy to help accommodate your celebration. Please contact us in advance to discuss any arrangements.",
  },
  {
    question:
      "Do adults need to remove their shoes, even if they are not playing with their child?",
    answer:
      "Yes, all adults must wear socks, even if they are just supervising. This helps keep our play area clean and safe, especially for younger children who may be crawling on the floor. Socks are required for both children and adults in all play areas.",
  },
  {
    question:
      "I’m attending a birthday party. Is there anything I should do before arriving?",
    answer:
      "Yes! Please make sure to sign our waiver online before you arrive (insert waiver link here). This will speed up your entry and ensure that everyone in your party can enjoy the play area right away. And don't forget to bring socks for everyone!",
  },
  {
    question: "Is there parking available at Kiddie Kingdom?",
    answer:
      "Yes, we have plenty of parking available. You'll find free parking on-site at 19444 Business Center Drive, Northridge, CA 91324. Parking is conveniently located near our main entrance for easy access.",
  },
  {
    question:
      "Can I leave and return on the same day? Is there an additional fee?",
    answer:
      "At this time, re-entry is not permitted. However, we hope you enjoy your visit to the fullest during your stay at Kiddie Kingdom. Our play sessions offer plenty of fun for everyone!",
  },
  {
    question: "What forms of payment do you accept?",
    answer:
      "We accept all major credit cards, including Visa, MasterCard, and American Express. We also accept contactless payments for added convenience.",
  },
  {
    question: "What does a membership include?",
    answer:
      "Our membership grants unlimited access to Kiddie Kingdom during open play hours. Members can enjoy the playground as often as they like, but be sure to check our website or social media for any updates on private events that may impact open play times.",
  },
  {
    question: "How do I book an event at Kiddie Kingdom?",
    answer:
      "Booking an event is easy! Just visit our website or call us at (insert phone number). We offer a variety of packages to suit your needs, and our team will work with you to ensure your event is special. Contact us for more details on availability and pricing.",
  },
];

const Faq = () => {
  const [activeIndex, setActiveIndex] = useState(null);

  const toggleAccordion = (index) => {
    setActiveIndex(activeIndex === index ? null : index);
  };

  return (
    <section className="faq-section">
      <h2 className="faq-title">FAQ</h2>
      <div className="faq-container">
        {FAQData.map((faq, index) => (
          <div
            key={index}
            className={`faq-item ${activeIndex === index ? "active" : ""}`}
          >
            <button
              className="faq-question"
              onClick={() => toggleAccordion(index)}
            >
              {faq.question}
              <span
                className={`faq-icon ${activeIndex === index ? "active" : ""}`}
              >
                <FontAwesomeIcon
                  icon={faCheckCircle}
                  style={{
                    transform:
                      activeIndex === index ? "rotate(620deg)" : "rotate(0deg)", // Rotate when active
                    color: activeIndex === index ? "#f0609a" : "#ed82ad", // Green when active, Orange otherwise
                    transition: "transform 0.3s ease, color 0.3s ease",
                  }}
                />
              </span>
            </button>
            <div
              className={`faq-answer ${activeIndex === index ? "show" : ""}`}
              style={{
                maxHeight: activeIndex === index ? "1000px" : "0",
                transition: "max-height 0.4s ease-in-out",
              }}
            >
              <p>{faq.answer}</p>
            </div>
          </div>
        ))}
      </div>
    </section>
  );
};

export default Faq;
