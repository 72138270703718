import React from "react";
import "../css/Privacy.css";
import Navbar from "./Navbar";
// import Footer from "./Footer";
const Privacy = () => {
  return (
    <div className="privacy-policy-container">
      <Navbar></Navbar>
      <header>
        <h1>Privacy Policy</h1>
      </header>
      <main>
        <section>
          <h2>Introduction</h2>
          <p>
            Our Privacy Policy outlines the practices regarding the handling of
            your information on kiddiekingdomplayground.com. It applies
            exclusively to data collected through this website and notifies you
            of the following:
          </p>
          <ul>
            <li>
              The types of personally identifiable information collected, how it
              is utilized, and with whom it may be shared.
            </li>
            <li>Your options regarding the use of your data.</li>
            <li>
              The security measures implemented to prevent misuse of your
              information.
            </li>
            <li>
              Procedures for correcting any inaccuracies in the information.
            </li>
          </ul>
        </section>

        <section>
          <h2>How We Collect, Use, and Share Your Information</h2>
          <p>
            We are the sole proprietors of the information gathered on this
            website. The only information we collect is what you voluntarily
            provide to us through email or other direct communication. We do not
            sell or rent your information to any third party.
          </p>
          <p>
            Your information will be used solely to respond to your inquiries or
            address the reason you contacted us. We do not share your
            information with external parties unless it is necessary to fulfill
            your request, such as processing an order.
          </p>
          <p>
            Unless you opt out, we may reach out to you via email in the future
            to share updates about special offers, new products or services, or
            changes to our privacy policy.
          </p>
        </section>

        <section>
          <h2>Managing and Controlling Your Information</h2>
          <p>
            You have the right to manage your interactions with us and can opt
            out of future communications at any time. By reaching out to us via
            the email or phone number provided on our website, you can:
          </p>
          <ul>
            <li>Review any data we have about you, if applicable.</li>
            <li>Request changes or corrections to your data.</li>
            <li>Ask us to delete any data we hold about you.</li>
            <li>Share any concerns about how your data is being used.</li>
          </ul>
        </section>

        <section>
          <h2>How We Protect Your Information</h2>
          <p>
            We prioritize the security of your information and take necessary
            precautions to safeguard it. When you provide sensitive information
            through our website, it is protected both online and offline.
          </p>
          <p>
            Sensitive information collected is encrypted and securely
            transmitted. You can confirm this by checking for a closed lock icon
            in your web browser or ensuring the webpage address begins with
            "https."
          </p>
          <p>
            In addition to online encryption, we protect your data offline.
            Access to personally identifiable information is limited to
            employees who require it to perform specific tasks, such as billing
            or customer service. Our computers and servers that store your
            information are maintained in a secure environment.
          </p>
        </section>

        <section>
          <h2>Cookies</h2>
          <p>
            We may use cookies on this website to enhance your experience. A
            cookie is a small data file stored on your hard drive that helps us
            improve access to our site and recognize returning visitors. For
            example, cookies can save you time by remembering your login
            details, so you don’t need to enter your password repeatedly.
            Additionally, cookies allow us to better understand user interests
            and improve your overall site experience. Please note that cookies
            are not linked to any personally identifiable information.
          </p>
        </section>

        <section>
          <h2>Links to Other Sites</h2>
          <p>
            Our website may contain links to external sites. We are not
            responsible for the content or privacy practices of these external
            sites. We encourage you to review the privacy policies of any site
            you visit that collects personally identifiable information.
          </p>
        </section>

        <section>
          <h2>Policy Updates</h2>
          <p>
            This Privacy Policy is subject to updates, and any changes will be
            posted on this page.
          </p>
          <p>
            If you believe we are not adhering to this policy, please contact us
            immediately at{" "}
            <a href="mailto:kiddiekingdomplayground@gmail.com">
              kiddiekingdomplayground@gmail.com
            </a>
            .
          </p>
        </section>

        {/* <footer>
          <p>
            Acknowledgment: We appreciate the Better Business Bureau’s “Tips on
            Establishing a Privacy Policy” for their guidance in developing this
            Privacy Policy.
          </p>
        </footer> */}
      </main>
      {/* <Footer /> */}
    </div>
  );
};

export default Privacy;
