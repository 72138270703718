import React, { useState, useEffect } from "react";
import "../css/Gallery.css"; // Link to the CSS file for the gallery
import img1 from "../assets/1.jpg";
import img2 from "../assets/2.jpg";
import img3 from "../assets/3.jpg";
import img4 from "../assets/4.jpg";
import img5 from "../assets/5.jpg";
import img6 from "../assets/6.jpg";
import img7 from "../assets/7.jpg";
import img8 from "../assets/8.jpg";
import img9 from "../assets/9.jpg";
import img10 from "../assets/10.jpg";
import img11 from "../assets/11.jpg";
import img12 from "../assets/12.jpg";
import img13 from "../assets/13.jpg";
import img14 from "../assets/14.jpg";
import img15 from "../assets/15.jpg";
import img16 from "../assets/16.jpg";
import img17 from "../assets/17.jpg";
import img18 from "../assets/18.jpg";
import img19 from "../assets/19.jpg";

// Array of image paths from the public folder
const images = [
  img1,
  img2,
  img3,
  img4,
  img5,
  img6,
  img7,
  img8,
  img9,
  img10,
  img11,
  img12,
  img13,
  img14,
  img15,
  img16,
  img17,
  img18,
  img19,
  // "/1.jpg",
  // "/2.jpg",
  // "/3.jpg",
  // "/4.jpg",
  // "/5.jpg",
  // "/6.jpg",
  // "/7.jpg",
  // "/8.jpg",
  // "/9.jpg",
  // "/10.jpg",
  // "/11.jpg",
  // "/12.jpg",
  // "/13.jpg",
  // "/14.jpg",
  // "/15.jpg",
  // "/16.jpg",
  // "/17.jpg",
  // "/18.jpg",
  // "/19.jpg",
  // `${process.env.PUBLIC_URL}/1.jpg`,
  // `${process.env.PUBLIC_URL}/2.jpg`,
  // `${process.env.PUBLIC_URL}/3.jpg`,
  // `${process.env.PUBLIC_URL}/4.jpg`,
  // `${process.env.PUBLIC_URL}/5.jpg`,
  // `${process.env.PUBLIC_URL}/6.jpg`,
  // `${process.env.PUBLIC_URL}/7.jpg`,
  // `${process.env.PUBLIC_URL}/8.jpg`,
  // `${process.env.PUBLIC_URL}/9.jpg`,
  // `${process.env.PUBLIC_URL}/10.jpg`,
  // `${process.env.PUBLIC_URL}/11.jpg`,
  // `${process.env.PUBLIC_URL}/12.jpg`,
  // `${process.env.PUBLIC_URL}/13.jpg`,
  // `${process.env.PUBLIC_URL}/14.jpg`,
  // `${process.env.PUBLIC_URL}/15.jpg`,
  // `${process.env.PUBLIC_URL}/16.jpg`,
  // `${process.env.PUBLIC_URL}/17.jpg`,
  // `${process.env.PUBLIC_URL}/18.jpg`,
  // `${process.env.PUBLIC_URL}/19.jpg`,

  // "/media/photo/1.jpg",
  // "/media/photo/2.jpg",
  // "/media/photo/3.jpg",
  // "/media/photo/4.jpg",
  // "/media/photo/5.jpg",
  // "/media/photo/6.jpg",
  // "/media/photo/7.jpg",
  // "/media/photo/8.jpg",
  // "/media/photo/9.jpg",
  // "/media/photo/10.jpg",
  // "/media/photo/11.jpg",
  // "/media/photo/12.jpg",
  // "/media/photo/13.jpg",
  // "/media/photo/14.jpg",
  // "/media/photo/15.jpg",
  // "/media/photo/16.jpg",
  // "/media/photo/17.jpg",
  // "/media/photo/18.jpg",
  // "/media/photo/19.jpg",
];

const Gallery = () => {
  const [currentIndex, setCurrentIndex] = useState(0);

  // Automatically change slides every 3 seconds
  useEffect(() => {
    const interval = setInterval(() => {
      goToNext();
    }, 3000);

    return () => clearInterval(interval); // Cleanup on component unmount
  }, [currentIndex]); // Dependency to update on index change

  const goToPrevious = () => {
    setCurrentIndex((prevIndex) =>
      prevIndex === 0 ? images.length - 1 : prevIndex - 1
    );
  };

  const goToNext = () => {
    setCurrentIndex((nextIndex) =>
      nextIndex === images.length - 1 ? 0 : nextIndex + 1
    );
  };

  return (
    <div className="gallery">
      <h2 className="gallery-title">Gallery</h2>
      <div className="gallery-section">
        <div className="gallery-container">
          {/* Previous Button */}
          <button className="gallery-button prev" onClick={goToPrevious}>
            &#10094;
          </button>

          {/* Single Slide */}
          <div className="gallery-slide">
            <img src={images[currentIndex]} alt={`Slide ${currentIndex + 1}`} />
          </div>

          {/* Next Button */}
          <button className="gallery-button next" onClick={goToNext}>
            &#10095;
          </button>
        </div>
        {/* Navigation dots */}
        {/* <div className="gallery-navigation">
        {images.map((_, index) => (
          <button
            key={index}
            className={`dot ${index === currentIndex ? "active" : ""}`}
            onClick={() => setCurrentIndex(index)}
          />
        ))}
      </div> */}
      </div>
    </div>
  );
};

export default Gallery;
