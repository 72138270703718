import React, { useEffect } from "react";
import "../css/HomePage.css";
import Packages from "./Packages";
import Navbar from "./Navbar";
import Contact from "./Contact";
import Faq from "./FAQData";
import RulesAndWaiver from "./RulesandWaiver";
import Footer from "./Footer";
import Gallery from "./Gallery";
import backgroundImage from "../assets/13.jpg";
import homepageBanner from "../assets/13.jpg";

const HomePage = () => {
  useEffect(() => {
    const handleScroll = () => {
      const imageContainer = document.querySelector(".image-container");
      if (imageContainer) {
        const scrollPosition = window.scrollY;
        imageContainer.style.transform = `translateY(${
          scrollPosition * 0.5
        }px)`; // Adjust scroll speed
      }
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  useEffect(() => {
    const handleHashChange = () => {
      const sectionId = window.location.hash.replace("#", "");
      if (sectionId) {
        const section = document.getElementById(sectionId);
        if (section) {
          section.scrollIntoView({ behavior: "smooth" });
        }
      }
    };

    handleHashChange();

    window.addEventListener("hashchange", handleHashChange);

    return () => {
      window.removeEventListener("hashchange", handleHashChange);
    };
  }, []);

  return (
    <div className="homepage">
      <Navbar />

      {/* Home Section with a Static Image and Text */}
      <section
        id="home"
        className="home-section relative"
        style={{ backgroundImage: `url(${backgroundImage})` }}
      >
        <div class="image-container">
          <img
            src={homepageBanner}
            alt="Homepage Banner"
            className="full-image"
          />
        </div>
        {/* <div className="image-container relative w-full h-full">
          <img
            src="/media/Photo/13.jpg" 
            alt="Homepage Banner"
            className="full-image object-cover w-full h-full"
          /> */}
        {/* Text Overlay */}
        {/* <div className="overlay-text absolute inset-0 flex flex-col justify-center items-center text-center z-10">
            <h1 className="text-4xl sm:text-6xl font-bold text-white drop-shadow-lg">
              Indoor Playground Near You in LA
            </h1>
            <p className="mt-4 text-lg sm:text-2xl text-white drop-shadow-md">
              Where fun never ends and memories are made!
            </p>
          </div> */}
        <div class="overlay-text">
          <h1>WELCOME TO KIDDIE KINGDOM</h1>
          <p>Where fun never ends and memories are made!</p>
        </div>
      </section>

      <section id="packages" className="section bg-[#d1e7dd] p-6 sm:p-10">
        <Packages />
      </section>
      <section id="gallery" className="section bg-[#ffefdb] p-6 sm:p-10">
        <Gallery />
      </section>
      <section id="rules-waiver" className="section bg-[#cfe2f3] p-6 sm:p-10">
        <RulesAndWaiver />
      </section>
      <section id="waiver" className="section bg-[#cfe2f3] p-6 sm:p-10">
        <Faq />
      </section>
      <section id="contact" className="section bg-[#fce5cd] p-6 sm:p-10">
        <Contact />
      </section>
      <section>
        <Footer />
      </section>
    </div>
  );
};

export default HomePage;
