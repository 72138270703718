import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import HomePage from "./pages/HomePage"; // Your homepage component
import PackagesDetail from "./pages/PackagesDetail";
import BookingForm from "./pages/BookingForm";
import Privacy from "./pages/Privacy";

function App() {
  return (
    <Router>
      <Routes>
        {/* Set HomePage as the default route */}
        <Route path="/" element={<HomePage />} />
        <Route path="/packagesdetail" element={<PackagesDetail />} />
        <Route path="/book" element={<BookingForm />} />
        {/* Fallback route */}
        <Route path="*" element={<h1>404 - Page Not Found</h1>} />
        <Route path="/privacy-policy" element={<Privacy></Privacy>}></Route>
      </Routes>
    </Router>
  );
}

export default App;
